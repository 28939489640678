.payment-container {
    margin: 10px;
}

.payment-logo {
    text-align: center !important;
    max-height: 100px;
    min-height: 100px;
}

.payment-logo img {
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 200px;
    max-height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}