.header {
  padding: 0 0 0 14px;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(216, 216, 216, 0.5);
  z-index: 1;
}

.header .headerLeft {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}

.header .headerLeft .menu {
  display: none;
  margin-right: 14px;
  margin-left: 10px;
  justify-content: center;
}

.header .headerLeft .menu i {
  font-size: 33px;
}

.header .headerLeft .logo {
  margin: 0;
  width: 65px;
  height: 41px;
}

.header .headerLeft .logo img {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
}

.header .headerLeft .title {
  margin-left: 23px;
}

.header .headerLeft .title h1 {
  margin: 0;
  font-size: 20px;
}

.header .headerRight {
  float: right;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
}

.header .action {
  height: 100%;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
  flex: 1 1;
  display: flex;
  position: relative;
}

.header .action:hover {
  background: rgba(0, 0, 0, 0.025);
}

.header .action:global(.opened) {
  background: rgba(0, 0, 0, 0.025);
}

.header .badge {
  background: red;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  position: absolute;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  top: 15px;
  right: 6px;
}

.backViewAs {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: solid 1px transparent;
  margin-right: 25px;
  transition: all 250ms ease;
}

.backViewAs:hover {
  border-bottom: solid 1px black;
}

.mobileMenu {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  width: 60%;
  z-index: 99;
  padding: 12px 24px;
  transition: all 0.3s;
  box-shadow: 5px 0 10px 10px rgba(0, 0, 0, 0.07);
  &.show {
    left: 0%;
  }
  .mobileMenuItems {
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem;
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      font-size: 14px;
      font-weight: 500;
      color: #66788a;
      &.active {
        box-shadow: inset 3px 0 0 0 #0091ff;
        background-color: rgba(0, 145, 255, 0.05);
        color: #0091ff;
      }
      &.disabled {
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.25);
        pointer-events: none;
        > span.disabledLabel {
          background-color: #0091ff;
          color: #fff;
          font-size: 9px;
          padding: 5px;
          border-radius: 4px;
          position: absolute;
          bottom: 4px;
          right: 73px;
        }
      }
      cursor: pointer;
      > i {
        font-weight: normal;
        margin-right: 10px;
      }
    }
  }
}

/* If the browser window is 575px or smaller */
@media only screen and (max-width: 575px) {
  .header .headerLeft .logo {
    width: 55px;
    height: auto;
  }
  .header .headerLeft .menu {
    display: flex;
  }
  .header .headerLeft .title {
    display: none;
  }
}
