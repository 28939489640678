@import "~antd/dist/antd.css";
@import "./scss/base.scss";
// [type="button"] {
//   height: 46px;
// }
.title-type-1 {
    border-bottom: solid 1px #e4e7eb;
    padding: 12px 0;
    >span {
        font-size: 20px;
        font-weight: bold;
        color: #242b32;
    }
}

.title-type-2 {
    // border-bottom: solid 1px #e4e7eb;
    padding: 12px 0;
    >span {
        font-size: 16px;
        font-weight: 500;
        color: #242b32;
    }
}


/**/

.search-result-list {
    margin-top: 16px;
    border: 1px dashed #f3f6fe;
    border-radius: 6px;
    background-color: rgba(243, 246, 254, 0.25);
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
    color: #0091ff;
    font-weight: 500;
    font-size: 16px;
}


/*  */

.selecth50 {
    width: 100%;
    height: 50px;
    margin-bottom: 4px;
    margin-top: 4px;
    overflow: hidden;
    .ant-select-selection {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .ant-select-selection__choice {
        margin-top: 0px !important;
        height: 35px !important;
        display: flex;
        align-items: center;
        background: aliceblue;
    }
}

.inputh50 {
    width: 100%;
    height: 50px;
    margin-bottom: 4px;
    margin-top: 4px;
    display: flex;
    align-items: center;
}

.buttonh36 {
    height: 36px;
}


/*  */

.ant-btn-primary {
    background-color: white;
    border-color: #0091ff;
    color: #0091ff;
    font-weight: 500;
}

.ant-btn-primary-share {
    border: none !important;
    color: #0091ff !important;
    background-color: white !important;
}

.ant-btn-primary-share:hover {
    opacity: 1 !important;
}

.ant-btn-primary-inverted {
    background-color: #0091ff !important;
    border-color: #0091ff !important;
    color: white !important;
    font-weight: 500 !important;
}

.ant-btn-primary-inverted:hover {
    opacity: 1 !important;
}

.ant-btn-primary:focus {
    background-color: white;
    border-color: #0091ff;
    color: #0091ff;
}

.ant-btn {
    &.btnSn {
        color: #768696;
        border-radius: 8px;
        height: 45px;
        &:hover {
            background-color: rgba(0, 145, 255, 0.05);
            color: #0091ff;
        }
    }
}

.macButton {
    background-color: #f3f6fe;
    min-width: 43px;
    &:hover {
        background-color: #f3f6fe;
    }
}


/*  */

.col5x5 {
    width: 20%;
    flex-basis: 20%;
}

@media only screen and (max-width: 768px) {
    .col5x5 {
        width: 33%;
        flex-basis: 33%;
    }
}

@media only screen and (max-width: 600px) {
    .col5x5 {
        width: 50%;
        flex-basis: 50%;
    }
}


/*  */

body {
    font-family: Roboto;
}

.ant-form-item-required::before {
    content: "";
}

.ant-form-item-with-help {
    margin-bottom: 0;
}

.ant-form-item-label {
    line-height: normal;
}

.ant-form-item-label>label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.05px;
    color: #66788a;
}

.ant-table-thead>tr>th {
    background-color: #fff;
}

.divider {
    background-color: rgba(63, 63, 68, 0.15);
    width: 100%;
    height: 1px;
    &.color2 {
        background-color: #f3f6fe;
    }
    &.vertical {
        width: 1px;
        height: 100%;
    }
}

.m0 {
    margin: 0px;
}

.fw500 {
    font-weight: 500 !important;
}

.size16 {
    font-size: 16px !important;
}

.size30 {
    font-size: 30px !important;
}

.lh-0 {
    line-height: normal !important;
}

.h1 {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.05px;
    color: #242b32;
    margin: 0;
    &.x2 {
        font-size: 30px;
    }
}

.h2 {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.05px;
    color: #242b32;
    margin: 0;
}

.h3 {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.05px;
    color: #242b32;
    margin: 0;
    &.x2 {
        font-size: 18px;
    }
}

.base {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.05px;
    color: #66788a;
}

.small {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.05px;
    color: #66788a;
}

.link {
    text-decoration: underline;
    font-weight: 500;
    color: #0091ff;
}

.colorPrimary {
    color: #0091ff;
}

.colorBlack {
    color: #242b32;
}

.textCenter {
    text-align: center;
}

.textRight {
    text-align: right;
}

.textJustify {
    text-align: justify;
}

.flexRow {
    display: flex;
    flex: 1;
    align-items: center;
}

.alignItemsTop {
    align-items: flex-start;
}

.alignItemsStretch {
    align-items: stretch;
}

.justifyContentSpace {
    justify-content: space-between;
}

.pointer {
    cursor: pointer;
}

.falseHover {
    cursor: pointer;
}

.falseHover:hover {
    color: #0091ff;
}

.falseHover.ant-dropdown-open {
    color: #0091ff;
}

.menuIcon {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #f3f6fe;
    justify-content: center;
    align-items: center;
    display: flex;
}

.menuIcon i {
    font-size: 14px;
}

.amenitiesTag {
    margin: 0 4px;
    display: inline-block;
    background-color: #f3f6fe;
    border-radius: 2px;
    padding: 3px 10px;
    font-size: 14px;
    color: #66788a;
    &.green {
        color: #01c0aa;
        background-color: rgba(1, 192, 170, 0.1);
    }
    &.red {
        color: rgb(250, 192, 192);
        background-color: rgba(250, 192, 192, 0.1);
    }
    &.primary {
        color: #0091ff;
        background-color: rgba(0, 145, 255, 0.1);
    }
}

figure {
    height: 100%;
    width: 100%;
    margin: 0;
}

figure img {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}

.badge {
    display: flex;
    width: 16px;
    height: 16px;
    font-size: 8px;
    background-color: red;
    color: white;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

//
//
//
.overLayParent {
    position: relative;
    &:hover {
        .overlayBottom {
            opacity: 0.3;
        }
        .overlayTop {
            opacity: 1;
        }
    }
    &.dont {
        &:hover {
            .overlayBottom {
                opacity: 1;
            }
            .overlayTop {
                opacity: 0;
            }
        }
    }
    .overlayBottom {
        transition: 0.25s ease;
        opacity: 1;
    }
    .overlayTop {
        transition: 0.25s ease;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}

//
//
//
.sms {
    overflow: hidden;
    p {
        margin: 0;
        text-align: left;
        white-space: pre-wrap;
    }
    .smsWrapper {
        width: 300px;
        margin: 24px auto 45px;
        position: relative;
        overflow: hidden;
        .smsRight {
            position: absolute;
            top: 110px;
            right: 10%;
            border-radius: 8px;
            background-color: #0091ff;
            font-size: 14px;
            font-weight: 500;
            color: white;
            padding: 14px;
        }
        .smsLeft {
            position: absolute;
            top: 173px;
            left: 10%;
            width: 224px;
            border-radius: 8px;
            background-color: #ebf1ff;
            font-size: 14px;
            font-weight: 500;
            color: #242b32;
            padding: 14px;
        }
    }
}

//
//
//
.card1 {
    border-radius: 8px;
    border: solid 1px #dfe5f9;
    overflow: hidden;
    .ant-card-body {
        padding: 0px;
    }
}

.card2 {
    border-radius: 4px;
    border: solid 1px #f3f6fe;
    overflow: hidden;
    .ant-card-body {
        padding: 0px;
    }
}

.bt {
    border-top: solid 1px #dfe5f9;
}

.display_none {
    display: none !important;
}

// My Custom Style SCSS
.ant-tabs-tab {
    font-size: 15px;
    font-weight: 500;
}

.width-100 {
    width: 100% !important;
}

.full-height-livestock {
    height: 180vh;
}

.timeline-box {
    background: #f7f7f7;
    padding: 20px;
}

.live-stock-detail p {
    font-size: 14px;
    line-height: 10px;
}

.ant-descriptions-title {
    margin-bottom: 0px !important;
}

.ant-descriptions-row>td {
    padding-bottom: 0px !important;
}

.text-right {
    float: right !important;
}

.farm-wizard {
    max-width: 600px;
    width: 95%;
}

.mb-20 {
    margin-top: 20px;
}

.female-row {
    background-color: #ffe4e8d6;
}

.male-row {
    background-color: #e2f6ffa3;
}

.ant-descriptions-item-content,
.ant-descriptions-item>span {
    font-size: 15px;
}

.ant-card-grid {
    width: 100% !important;
    padding: 0px;
}

.ant-card-grid-hoverable:hover {
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 0 8px 64px rgb(0 0 0 / 15%);
    box-shadow: 0 8px 64px rgb(0 0 0 / 15%);
}