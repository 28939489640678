.animal-id {
    background: #0091ff;
    p {
        text-align: center;
        font-size: 20px;
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0px !important;
    }
}

.full-height {
    height: 95vh;
}

.milking-menu {
    padding: 0px !important;
    background-color: #fff;
}

.menu-button {
    text-align: center;
    border: 1px solid #ccc;
    padding-top: 10px;
    p {
        padding-top: 5px;
        padding-bottom: 5px;
        color: #333;
        font-size: 15px;
    }
    img {
        height: 30px !important;
    }
}

.menu-button:hover {
    box-shadow: 2px 2px 5px 2px #e0dfdf;
    animation-delay: 2s;
    p {
        color: #0091ff;
    }
}

.footer-title {
    font-size: 20px;
    font-weight: 500;
    text-align: center !important;
}

.time {
    font-size: 26px;
    font-weight: 800;
}

.green-label {
    color: #fff;
    background-color: rgb(57, 189, 31);
    padding: 2px 10px;
}

.footer-description {
    font-size: 15px;
}

.selected-row {
    background-color: #bebebf21;
    animation-delay: 2s;
}