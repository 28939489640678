.siderHeader {
  padding: 20px 13px;
  background-color: rgba(0, 145, 255, 100%);
}

.siderHeader .siderHeaderContent {
  padding: 10px 0 5px;
  display: flex;
  align-items: center;
}

.ant-menu-item {
  margin: 0 !important;
}

.profile-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 14px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 20%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: inline-block;
  text-align: center !important;
}

.siderHeaderImage img {
  width: 50px;
  /* height: 50px; */
}

.siderHeader .span1 {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.05px;
  color: #fff;
  display: block;
}

.text-center {
  text-align: center !important;
}

.siderHeader .span2 {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.04px;
  color: #66788a;
}

.siderHeaderDivider {
  width: 100%;
  height: 1px;
  background-color: #e4e7eb;
}

.ant-layout-sider {
  box-shadow: 5px 1px 8px rgba(0, 0, 0, 0.09%);
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.siderMenu {
  flex: 1;

  .ant-menu-item-disabled {
    .disabled-label {
      background: #0091ff;
      color: white;
      font-size: 9px;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 20px;
      width: 60px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.siderMenu {
  color: #333;
  // height: calc(100% - 114px);
  border-right: 0;
}

.siderMenu .ant-menu-item {
  padding: 0 !important;
}

.siderMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f3f6fe;
}

.siderMenu .ant-menu-item-selected {
  color: #0091ff;
}

.siderMenu .ant-menu-item-selected:after {
  content: none;
}

.siderOption {
  display: flex;
  align-items: center;
  padding: 0 13px;
}

.siderMenu .ant-menu-item-selected .siderOption {
  background-color: rgba(0, 145, 255, 0.05);
}

.siderOptionIcon {
  font-size: 20px;
  width: 25px;
  /* ugly fix */
}

.siderOptionLabel {
  font-size: 20px;
  font-weight: 500;
}

.ant-tooltip-open {
  opacity: 1;
}

.siderCollapsed .hideOnCollapsed {
  display: none;
}

.siderCollapsed .siderHeaderImage {
  margin: 0 auto;
}

.siderCollapsed .siderOption {
  height: 100%;
  text-align: center;
}

.siderBottom {
  width: 100%;
  padding: 24px;
  // position: absolute;
  // bottom: 24px;
  .bottomHeader {
    border-bottom: solid 1px #e4e7eb;
    padding-bottom: 5px;
    margin-bottom: 5px;
    span {
      font-size: 14px;
      font-weight: 500;
      color: #66788a;
    }
  }
  .companyItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    .logo {
      width: 90%;
      border-radius: 4px;
      border: solid 1px #f3f6fe;
      // margin-right: 10px;
      img {
        width: 100%;
      }
    }
    .name {
      flex: 1;
      span {
        font-size: 14px;
        font-weight: 500;
        color: #242b32;
      }
    }
    .name-u {
      flex: 1;
      span {
        font-size: 14px;
        color: #66788a;
      }
    }
    .actions {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 5px 0;
      > div {
        flex: 1;
        text-align: center;
        &:first-child {
          text-align: right;
          padding-right: 5px;
        }
        &:last-child {
          text-align: left;
          padding-left: 5px;
        }
      }
    }
  }
  // .companyInfo {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: flex-start;
  //   justify-content: space-between;
  //   span {
  //     font-size: 14px;
  //     &:first-child {
  //       color: #242b32;
  //     }
  //     &:nth-child(2) {
  //       color: #66788a;
  //     }
  //   }
  // }
}

/* If the browser window is 768px or smaller */
@media only screen and (max-width: 768px) {
  // .siderBottom {
  //   display: none;
  // }
}
