.loginContainer {
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/images/farm-bg.jpg');
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
}

.y {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.z {
    background: #fff;
    padding: 47px 63px;
    border-radius: 4px;
    box-sizing: content-box;
}

.login-form {
    max-width: 360px;
    width: 95%;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.login-form .logo {
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
    width: 72px;
    height: auto;
}

.login-form .intro {
    padding: 34px 0;
}

.login-form .intro h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.06px;
    color: #242b32;
}

.login-form .intro h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.05px;
    color: #66788a;
}